import { Injectable } from "@angular/core";
import { Workers } from "../datasources/workers"; 
import { Users } from "../datasources/users"; 


@Injectable({ providedIn: 'root'})

export class CurrentPageService {
    private workersList: Workers[];
    private usersList: Users[];

    getById (workerId: number) {
        const worker = this.workersList.find( item => {
            return item.wrkId === workerId;
        })
        return worker
    }

    getUserById (userId: number) {
        console.log("getUserById", userId)
        
        if(this.usersList !== undefined && userId && this.usersList.length) {
            const user = this.usersList.find( item => {
                return item.id === userId;
            })
            return user
        }
        return null;
    }


    setWorkers (workers: Workers[]) {
        this.workersList = workers
        console.log("private workers", this.workersList)
    }

    setUsers (users: Users[]) {
        this.usersList = users
        console.log("private users", this.usersList)
    }

}