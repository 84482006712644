import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})

export class ErrorPageComponent {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
      ) { }

      ngOnInit() {
        console.log("curren route", this.router.url)
        console.log("curren route params", this.route.snapshot.params)
        }      
}