import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

//import { AngularFireAuth } from "@angular/fire/auth";
//import firebase from 'firebase/app'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';

@Injectable()
export class AuthService {
  //private user: Observable<firebase.User>;
  //private userDetails: firebase.User = null;
  private userDetails: Object = null;
  private _name: string = null;
  private _token: string = null;
  private _admin: boolean = false;
  private _user: {
      drole: number,
      id: number,
      name: string,
      oiibId: string,
      izba: string,
      signerId: number,
      surname: string,
      username: string
  };

  private _userSetting: {
    id: number,
    signerId: number,
    pin: string,
    webservice: string,
    autosign: number,
    oiibId: string,
    autoUpdateWebservice: string,
    function: string,
    signError: string,
    signBegin: string,
    signCounter: number,
    savePin: number,
};

  //LoginForm: FormGroup;

  constructor(private http: HttpClient, public router: Router) {

    /*this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );*/

  }

  ngOnInit(): void {
    /*this.LoginForm = this.formBuilder.group({
      username: '',
      password: ''
    });*/
  }
  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  getToken() {
    return this._token;
  }

  setToken(token: string) {
    this._token = token
  }

  setAdmin(admin: boolean) {
    this._admin = admin
  }


  setName(name: string) {
    this._name = name
  }

  setUser(user: any) {
    this._user = user
  }

  getUser() {
    return this._user
  }

  setUserSetting(userSetting: any) {
    this._userSetting = userSetting
  }

  getUserSettings() {
    return this._userSetting
  }

  getAdmin() {
    return this._admin
  }

  getName() {
    return this._user.name + ' ' + this._user.surname
  }

  signinUser(email: string, password: string) {

    const formData = new FormData();
    formData.append('username', email)
    formData.append('password', password)

    return this.http.post( environment.apiUrl + "/login", formData)
  }

  logout() {
    //this._firebaseAuth.signOut();

    console.log("logout")

    localStorage.removeItem('userToken')
    localStorage.removeItem('user')
    localStorage.removeItem('userSettings')
    localStorage.removeItem('_admin')

    this.router.navigate(['/pages/login']);
  }

  isAuthenticated() {

    const tokenFromLS = localStorage.getItem('userToken')

    if(tokenFromLS !== null) {
      console.log("tokenFromLS", tokenFromLS)
      this._token = tokenFromLS

      const _admin = localStorage.getItem('_admin')
      console.log("restore _admin", _admin)
      //this._admin = _admin
      const user = JSON.parse(localStorage.getItem('user'))
      this._user = user
      const userSettings = JSON.parse(localStorage.getItem('userSetting'))
      this._userSetting = userSettings
    }

    if (this._token !== null)
      return true;
    else
      return false;
  }
}
